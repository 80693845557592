export default function () {

	$('.js-flight-option:not(.js-flight-option-done)').each(function () {
		var checkbox = $(this);
		var container = $('.m-booking-block__flights');

		//check which container has checked radio
		container.removeClass('active');
		if ($('.js-flight-option:checked').closest(container).addClass('active'));

		// click the radio
		checkbox.on('change', function () {
			container.removeClass('active');
			checkbox.closest(container).addClass('active');
		});

		//click the container
		container.on('click', function () {
			var option = $(this).find('.js-flight-option');
			option.prop('checked', true);
			option.trigger("change");
			container.removeClass('active');
			$(this).addClass('active');
		});

		// done
		checkbox.addClass('js-flight-option-done');
	});

}
